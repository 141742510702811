//export const baseUrl = 'http://api.mercury-server.test/'
export const baseUrl = 'https://api.an-merkuriy.ru'

// export const clientUrl = 'http://localhost:8087'
export const clientUrl = 'https://an-merkuriy.ru'

export const baseTimeout = 60000
export const maxFileSize = 10 * 1024 * 1024

export const advertSources = {
  donRio: 22,
  public: 1524
}

export const advertStatuses = {
  active: 2,
  deleted: 5,
  sold: 6,
  scrappedBefore: 13
}

export const list = {
  value: 'list',
  label: 'Выборка'
}

export const file = {
  value: 'file',
  label: 'Выгрузка'
}

export const compilationTypes = [
  list,
  file
]

export const phoneCallStatuses = {
  available: 65,
  unavailable: 66,
  notAnswer: 67,
  clear: 1485,
  busy: 1492
}

export const phoneStatuses = {
  existed: 61,
  substitute: 21,
  notCalled: 20
}

export const phoneMask = '8 ### ### ## ##'

export const advertTypeItems = [
  {
    text: 'Все',
    value: {
      isVerified: null,
      advStatus: null
    }
  },
  {
    text: 'Удаленные',
    value: {
      isVerified: null,
      advStatus: advertStatuses.deleted
    }
  },
  {
    text: 'Проданные',
    value: {
      isVerified: null,
      advStatus: advertStatuses.sold
    }
  },
  {
    text: 'Подтвержденные',
    value: {
      isVerified: 1,
      advStatus: advertStatuses.active
    }
  },
  {
    text: 'Не подтвержденные',
    value: {
      isVerified: 0,
      advStatus: null
    }
  }
]
